
// Do not edit directly
// Generated on Mon, 24 Jun 2024 13:21:13 GMT

$color-brand-blue-light: #4ea5d2ff;
$color-brand-green-neon: #90dd3eff;
$color-brand-green-main: #1bce6cff;
$color-brand-blue-dark: #043172ff;
$color-brand-white: #ffffffff;
$color-gray-n900: #000d36ff;
$color-gray-n800: #172b4dff;
$color-gray-n700: #253858ff;
$color-gray-n600: #344563ff;
$color-gray-n20: #f4f5f7ff;
$color-gray-n0: #ffffffff;
$color-gray-n500: #42526eff;
$color-gray-n10: #fafbfcff;
$color-gray-n30: #ebecf0ff;
$color-gray-n40: #dfe1e6ff;
$color-gray-n50: #c1c7d0ff;
$color-gray-n60: #b3bac5ff;
$color-gray-n70: #a5adbaff;
$color-gray-n80: #97a0afff;
$color-gray-n90: #8993a4ff;
$color-gray-n100: #7a869aff;
$color-gray-n200: #6b778cff;
$color-gray-n300: #5e6c84ff;
$color-gray-n400: #505f79ff;
$color-blue-darker: #000d36ff;
$color-blue-dark: #021c52ff;
$color-blue-main: #043172ff;
$color-blue-light: #5996d4ff;
$color-blue-lighter: #c8e3f7ff;
$color-green-darker: #056255ff;
$color-green-dark: #0d9465ff;
$color-green-main: #1bce6cff;
$color-green-light: #73f092ff;
$color-green-lighter: #d0fcd2ff;
$color-red-darker: #4c0307ff;
$color-red-dark: #731108ff;
$color-red-main: #a03110ff;
$color-red-light: #e29568ff;
$color-red-lighter: #fae4cdff;
$color-yellow-darker: #514500ff;
$color-yellow-dark: #7a6b00ff;
$color-yellow-main: #aa9901ff;
$color-yellow-light: #e5da5cff;
$color-yellow-lighter: #faf7c8ff;
$color-cyan-darker: #276297ff;
$color-cyan-dark: #3982b4ff;
$color-cyan-main: #4ea5d2ff;
$color-cyan-light: #bbf1faff;
$color-cyan-lighter: #dcfafcff;
$color-primary-default: #043172ff;
$color-primary-light: #5996d4ff;
$color-primary-lighter: #c8e3f7ff;
$color-primary-dark: #021c52ff;
$color-primary-darker: #000d36ff;
$color-secondary-default: #1bce6cff;
$color-secondary-light: #73f092ff;
$color-secondary-lighter: #d0fcd2ff;
$color-secondary-dark: #0d9465ff;
$color-secondary-darker: #056255ff;
$color-success-lighter: #d0fcd2ff;
$color-success-light: #73f092ff;
$color-success-default: #1bce6cff;
$color-success-dark: #0d9465ff;
$color-success-darker: #056255ff;
$color-error-lighter: #fae4cdff;
$color-error-light: #e29568ff;
$color-error-default: #a03110ff;
$color-error-dark: #731108ff;
$color-error-darker: #4c0307ff;
$color-warning-lighter: #faf7c8ff;
$color-warning-light: #e5da5cff;
$color-warning-default: #aa9901ff;
$color-warning-dark: #7a6b00ff;
$color-warning-darker: #514500ff;
$color-tertiary-lighter: #dcfafcff;
$color-tertiary-light: #bbf1faff;
$color-tertiary-default: #4ea5d2ff;
$color-tertiary-dark: #3982b4ff;
$color-tertiary-darker: #276297ff;
$color-white: #ffffffff;
$color-black: #000000ff;
$color-information-lighter: #c8e3f7ff;
$color-information-light: #5996d4ff;
$color-information-default: #043172ff;
$color-information-dark: #021c52ff;
$color-information-darker: #000d36ff;
$color-highlight: #1bce6cff;
$color-neutral-light-0: #ffffffff;
$color-neutral-light-10: #fafbfcff;
$color-neutral-light-20: #f4f5f7ff;
$color-neutral-light-30: #ebecf0ff;
$color-neutral-light-40: #dfe1e6ff;
$color-neutral-light-50: #c1c7d0ff;
$color-neutral-light-60: #b3bac5ff;
$color-neutral-light-70: #a5adbaff;
$color-neutral-light-80: #97a0afff;
$color-neutral-light-90: #8993a4ff;
$color-neutral-dark-100: #7a869aff;
$color-neutral-dark-200: #6b778cff;
$color-neutral-dark-300: #5e6c84ff;
$color-neutral-dark-400: #505f79ff;
$color-neutral-dark-500: #42526eff;
$color-neutral-dark-600: #344563ff;
$color-neutral-dark-700: #253858ff;
$color-neutral-dark-800: #172b4dff;
$color-neutral-dark-900: #000d36ff;
$color-text-default: #021c52ff;
$color-text-link: #0d9465ff;
$color-text-disabled: #b3bac5ff;
$color-text-success: #056255ff;
$color-text-information: #021c52ff;
$color-text-warning: #514500ff;
$color-text-error: #a03110ff;
$color-text-action: #021c52ff;
$color-text-subtle: #7a869aff;
$color-text-invert: #ffffffff;
$color-text-status-in-progress: #021c52ff;
$color-text-status-awaiting-approval: #4c0307ff;
$color-text-status-shared: #4c0307ff;
$color-text-status-waiting-for-signing: #514500ff;
$color-text-status-signed: #42526eff;
$color-text-status-finished: #42526eff;
$color-text-status-approved: #42526eff;
$color-text-price: #0d9465ff;
$color-background-default: #ffffffff;
$color-background-gray: #f4f5f7ff;
$color-background-disabled: #dfe1e6ff;
$color-background-success: #d0fcd2ff;
$color-background-error: #fae4cdff;
$color-background-information: #c8e3f7ff;
$color-background-highlight: #1bce6cff;
$color-background-invert: #021c52ff;
$color-background-invert-bold: #000d36ff;
$color-background-overlay: #000d3633;
$color-background-warning: #faf7c8ff;
$color-background-status-in-progress: #c8e3f7ff;
$color-background-status-awaiting-approval: #fae4cdff;
$color-background-status-shared: #fae4cdff;
$color-background-status-waiting-for-signing: #faf7c8ff;
$color-background-status-signed: #ebecf0ff;
$color-background-status-finished: #ebecf0ff;
$color-background-status-approved: #ebecf0ff;
$color-background-gray-bold: #7a869aff;
$color-background-line: #ebecf0ff;
$color-background-line-bold: #dfe1e6ff;
$color-background-active: #021c52ff;
$color-border-primary: #021c52ff;
$color-border-secondary: #ffffffff;
$color-border-information: #021c52ff;
$color-border-success: #056255ff;
$color-border-warning: #514500ff;
$color-border-error: #a03110ff;
$color-border-highlight: #1bce6cff;
$color-border-disabled: #b3bac5ff;
$color-border-gray: #ebecf0ff;
$color-border-gray-bold: #dfe1e6ff;
$color-border-status-in-progress: #021c52ff;
$color-border-status-awaiting-approval: #4c0307ff;
$color-border-status-shared: #4c0307ff;
$color-border-status-waiting-for-signing: #514500ff;
$color-border-status-signed: #42526eff;
$color-border-status-finished: #42526eff;
$color-border-status-approved: #42526eff;
$color-border-status-awaiting-approval-2: #e29568ff;
$color-border-status-in-progress-lighter: #c8e3f7ff;
$color-border-status-awaiting-approval-lighter: #fae4cdff;
$color-border-status-shared-lighter: #fae4cdff;
$color-border-status-waiting-for-signing-lighter: #faf7c8ff;
$color-border-status-signed-lighter: #f4f5f7ff;
$color-border-status-finished-lighter: #f4f5f7ff;
$color-border-status-approved-lighter: #f4f5f7ff;
$color-border-subtle: #7a869aff;
$color-border-focus: #021c52ff;
$color-border-active: #021c52ff;
$color-theme: #021c52ff;
