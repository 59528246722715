@use 'variables' as *;

@import 'libs/shared/ui/src/lib/assets/fonts/switzer.css';
@import 'reset-css/reset.css';
@import '@angular/cdk/overlay-prebuilt.css';

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  background-color: $color-background-default;
}

body,
input,
textarea {
  font-family: Switzer, sans-serif;
  font-size: 14px;
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}
