/**
* @license
*
* Font Family: Switzer
* Designed by: Jérémie Hornus
* URL: https://www.fontshare.com/fonts/switzer
* © 2024 Indian Type Foundry
*
* Switzer Variable (Variable font)
* Switzer VariableItalic (Variable font)

*
*/
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 400.0;
*
* available axes:
'wght' (range from 100.0 to 900.0
*/
@font-face {
  font-family: 'Switzer';
  src: url('./switzer/Switzer-Variable.woff2') format('woff2'),
       url('./switzer/Switzer-Variable.woff') format('woff'),
       url('./switzer/Switzer-Variable.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 400.0;
*
* available axes:
'wght' (range from 100.0 to 900.0
*/
@font-face {
  font-family: 'Switzer';
  src: url('./switzer/Switzer-VariableItalic.woff2') format('woff2'),
       url('./switzer/Switzer-VariableItalic.woff') format('woff'),
       url('./switzer/Switzer-VariableItalic.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
}

